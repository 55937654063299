
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyD2PJQNxIODBfpRzEGAYZJijsWhKWmyHcc",
  authDomain: "move-it-helper.firebaseapp.com",
  databaseURL: "https://move-it-helper.firebaseio.com",
  projectId: "move-it-helper",
  storageBucket: "move-it-helper.appspot.com",
  messagingSenderId: "43017353273",
  appId: "1:43017353273:web:dab2085456a1f1b737f5e8",
  measurementId: "G-QW7MCKMJTF"
};


initializeApp(firebaseConfig);

const messaging = getMessaging();

const requestForToken = () => {
   return getToken(messaging, { vapidKey: `BEwn_M-O4__Lg5EPAcfZn90w_ps2WvCVtJ38PGSf6OV6urFH4dZzS9sPeTAYM3oqF4SzDFVeucQN4NAZ8r2B23s` });
};

const onMessageListener = () =>
  new Promise((resolve) => {    
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export {onMessageListener, requestForToken};

  