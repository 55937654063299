import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, generatePath, useHistory } from 'react-router-dom';
import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet
import Service from './../service';
import toast, { Toaster } from 'react-hot-toast';
import React, { useState, useCallback, Component, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useFormik } from 'formik';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
// const config = require('./../../../config.json');

const api = new Service();
const City = (props) => {

    useEffect(() => {
        getCityList();
    }, [])
    const [config, setconfig] = useState(props.config);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [Active, setActive] = useState([]);
    const [Deactive, setDeactive] = useState([]);
    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            city: '',
            state: '',
            zipcode: ''
        },
        validationSchema: yup.object({
            first_name: yup.string().required('Please enter your first name.'),
            last_name: yup.string().required('Please enter your last name.'),
            email: yup.string().required('Please enter your email.'),
            city: yup.string().required('Please enter your city.'),
            state: yup.string().required('Please enter your state'),
            zipcode: yup.string().required('Please enter your zipcode')
        }),
        onSubmit: values => {
            console.log(values);
            setLoading(true)
            api.postApi('save-suggestion', values).then(response => {
                toast.success(response.message);
                formik.resetForm()
                setLoading(false)
            }).catch(error => {
                setLoading(false)
                toast.error(error.message);
            });
        }
    });

    const getCityList = () => {
        api.getApi('location', {}).then(response => {
            if (response.data) {
                setActive(response.data.Active)
                setDeactive(response.data.Deactive)
            }
        }).catch(error => {
            toast.error(error.message);
        });
    }

    return (
        <>
            <MetaHelmet title="Move It Cities | Move It Locations – Move It" description="Find a Move It on demand Delivery and moving services in your hometown! Let Us Know if You Want Move It in Your city" />
            <Toaster position="top-right" reverseOrder={false} />
            <Header></Header>
            <main>
                <section className="hero clean bg-pod" style={{ backgroundImage: "url(web/img/about-1.jpg)" }}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Move It Cities</h1>
                            </div>
                        </div>
                    </article>
                </section>
                <div className="container mb-5 nopadd-0px">
                    <div className="container">
                        <h2 className="ttl_lg"></h2>
                        <div className="row">
                            <div className="col-md-8 ">
                                <div className="live__map_wrap">


                                    <Link to="" className="loc_map-mark" data-id="" title="Denver, CO" style={{ top: "40%", left: "32.5%" }}>
                                        <img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
                                        <div className='pulse'></div>
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Las Vegas, NV" style={{ top: "49%", left: "13.5%" }}>
                                        <img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
                                        <div className='pulse'></div>
                                    </Link>
                                    <Link to="" className="loc_map-mark sandngo" data-id="" title="San Diego, CA" style={{ top: "57%", left: "7.9%" }}>
                                        <img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
                                        <div className='pulse'></div>
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Tucson, AZ" style={{ top: "60%", left: "20%" }}>
                                        <img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
                                        <div className='pulse'></div>
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Phoenix, AZ" style={{ top: "58%", left: "19.5%" }}>
                                        <img src="web/img/google-maps.svg" data-src="web/img/google-maps.svg" alt="" />
                                        <div className='pulse'></div>
                                    </Link>



                                    <Link to="" className="loc_map-mark" data-id="" title="Albuquerque" style={{ top: "47%", left: "25.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Salt Lake City, UT" style={{ top: "26%", left: "23.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>

                                    <Link to="" className="loc_map-mark" data-id="" title="Austin, TX" style={{ top: "70%", left: "47%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Dallas, TX" style={{ top: "66%", left: "47%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="San Jose, CA" style={{ top: "38%", left: "1%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>


                                    <Link to="" className="loc_map-mark" data-id="" title="Los Angeles, CA" style={{ top: "51%", left: "4.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark m-bile" data-id="" title="Seattle, WA" style={{ top: "8%", left: "7%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Chicago, IL" style={{ top: "33%", left: "64.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Houston, TX" style={{ top: "71.5%", left: "52.4%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>

                                    <Link to="" className="loc_map-mark" data-id="" title="Atlanta, GA" style={{ top: "57%", left: "73.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark mia-bile" data-id="" title="Miami, FL" style={{ top: "81%", left: "84%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="San Francisco, CA" style={{ top: "36%", left: "0%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Boston, MA" style={{ top: "23.5%", left: "93.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="New York, NY" style={{ top: " 28%", left: "89%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Nashville, TN" style={{ top: "51.25%", left: "68%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Dallas, TX" style={{ top: "66%", left: "47%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Charlotte, NC" style={{ top: "52.5%", left: "80%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Indianapolis, IN" style={{ top: "39.65%", left: "68%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="St. Louis, MO" style={{ top: "41.65%", left: "58%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Orange County, CA" style={{ top: "52.5%", left: "6.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Cleveland, OH" style={{ top: "33.5%", left: "75%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Minneapolis, MN" style={{ top: "23.5%", left: "54.5%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Philadelphia, PA" style={{ top: "34%", left: "87.2%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Kansas City, MO" style={{ top: "40.65%", left: "50%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>
                                    <Link to="" className="loc_map-mark" data-id="" title="Boise, ID" style={{ top: "19%", left: "13%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>

                                    <Link to="" className="loc_map-mark" data-id="" title="Little Rock, AR" style={{ top: "51.65%", left: "53%" }}>
                                        <img src="web/img/map-pin1.svg" data-src="web/img/map-pin1.svg" alt="" style={{ width: "15px" }} />
                                    </Link>

                                    <img className="map_img" src="web/img/old-moveit-map.svg" data-src="web/img/old-moveit-map.svg" alt="" />

                                </div>

                            </div>
                            <div className="col-md-4">
                                <div className="servingbox newtop">
                                    <img src="web/img/google-maps.svg" alt="" /> <span className="f-26"> Available Now </span>
                                    <ul className="citylist">
                                        {Active.length > 0 && Active.map((option, index) => (
                                            <li key={index}>
                                                {config.PRODUCTION_MODE ?
                                                    <>
                                                        <Link to={option.location_name_alias}>{option.name}</Link>
                                                    </>
                                                    : option.name}
                                            </li>
                                        ))}

                                    </ul>


                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="servingbox ">
                                    <img src="web/img/map-pin1.svg" alt="" /> <span className="f-26"> Coming Soon </span>
                                    <ul className="citylist" >
                                        {Deactive.length > 0 && Deactive.map((option, index) => (
                                            <li key={index}>
                                                <>
                                                    {/* <Link to={option.location_name_alias}>{option.name}</Link> */}
                                                    {option.name}
                                                </>
                                            </li>
                                        ))}

                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <section className="slate">
                    <div className="container">
                        <div className="row d-flex align-items-center justify-content-center" data-aos="fade-left" data-aos-delay="100">
                            <div className="col-md-12 col-lg-10">
                                <p className="f-22">Fill out the form below to let us know you want Move It Cities to come to your area.</p>
                            </div>
                            <div className="col-md-12 col-lg-10">
                                <div className="contact-wrapper">
                                    <form action="#" className="job-application-form cituform-box login-page row" onSubmit={formik.handleSubmit}>
                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-user"></i>
                                            <input type="text" name="first_name"  {...formik.getFieldProps("first_name")} />
                                            <span>First Name</span>
                                            {formik.touched.first_name && formik.errors.first_name ? <div className="text-danger">{formik.errors.first_name}</div> : null}
                                        </div>

                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-user"></i>
                                            <input type="text" name="last_name"   {...formik.getFieldProps("last_name")} />
                                            <span>Last Name</span>
                                            {formik.touched.last_name && formik.errors.last_name ? <div className="text-danger">{formik.errors.last_name}</div> : null}
                                        </div>

                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-envelope"></i>
                                            <input type="text" name="email"   {...formik.getFieldProps("email")} />
                                            <span>Email</span>
                                            {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                                        </div>

                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-map-pin"></i>
                                            <input type="text" name="city"   {...formik.getFieldProps("city")} />
                                            <span>City</span>
                                            {formik.touched.city && formik.errors.city ? <div className="text-danger">{formik.errors.city}</div> : null}
                                        </div>

                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-envelope"></i>
                                            <input type="text" name="state"  {...formik.getFieldProps("state")} />
                                            <span>State</span>
                                            {formik.touched.state && formik.errors.state ? <div className="text-danger">{formik.errors.state}</div> : null}
                                        </div>

                                        <div className="form-row col-md-6 col-lg-6">
                                            <i className="fa fa-location-arrow"></i>
                                            <input type="text" name="zipcode"  {...formik.getFieldProps("zipcode")} />
                                            <span>Zip Code</span>
                                            {formik.touched.zipcode && formik.errors.zipcode ? <div className="text-danger">{formik.errors.zipcode}</div> : null}
                                        </div>


                                        <div className="col-md-12">
                                            <div className="form-row">
                                                <button className="full" type="submit"><span>SUGGEST LOCATION</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Link className="section red padd-50" to="/contact-us">
                    <article className="text-center">
                        <h6 className="h2">Still have  <strong> questions? </strong></h6>
                        <span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
                    </article>
                </Link>
            </main>
            <Footer loading={loading}></Footer>
        </>
    );
}
export default City;

