// import firebase from 'firebase';
  
  import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyD2PJQNxIODBfpRzEGAYZJijsWhKWmyHcc",
  authDomain: "move-it-helper.firebaseapp.com",
  databaseURL: "https://move-it-helper.firebaseio.com",
  projectId: "move-it-helper",
  storageBucket: "move-it-helper.appspot.com",
  messagingSenderId: "43017353273",
  appId: "1:43017353273:web:dab2085456a1f1b737f5e8",
  measurementId: "G-QW7MCKMJTF"
};
  
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export {auth , firebase};