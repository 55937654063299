import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from './../layout/Header'; //Include Heder
import Footer from './../layout/Footer'; //Include Footer
import Slider from "react-slick";

// import {Helmet} from "react-helmet";

import MetaHelmet from './../metaHelmet'; //Include metaHelmet


var slider_settings = {
	pauseOnHover: true,
	autoplay: true,
	autoplayspeed: 2000,
	speed: 1000,
	centerMode: true,
	centerPadding: '0%',
	slidesToShow: 3,
	slidesToScroll: 3,
	arrows: true,
	dots: false,
	draggable: true,
	responsive: [{
		breakpoint: 991,
		settings: {
			slidesToShow: 2,
		}
	},
	{
		breakpoint: 580,
		settings: {
			slidesToShow: 1,
		}
	}]

};

var slider_settings_2 = {
	speed: 1500,
	slidesToShow: 3,
	slidesToScroll: 2,
	autoplay: true,
	autoplaySpeed: 2000,
	dots: true,
	centerMode: true,
	responsive: [{
		breakpoint: 1024,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 1,
			// centerMode: true,

		}

	}, {
		breakpoint: 800,
		settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
			dots: true,
			infinite: true,

		}
	}, {
		breakpoint: 580,
		settings: {
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 2000,
		}
	}]
};

if(localStorage.getItem("login") == 1){
   localStorage.setItem("login",1)
}else{
	localStorage.setItem("login",0)
}

const Home = (props) => {
	const [section, setsection] = useState(4);

	return (
		<>
			<MetaHelmet title="Move It - On Demand Delivery & Move" description="Move It will provide you the peace of mind and convenience of safe store pick up and delivery, moving your belongings from one place to another, junk removal or just extra Muscle." />
			<section id="home-intro" className="hero full home">

				<div className="video-wrapper">
					<video src="web/video/moveit-video-new.mp4" type="video/mp4" muted loop autoPlay={"autoplay"} preload="Yes" playsInline={true}></video>
				</div>


				{/*<div id="player"></div>*/}

				{/*<iframe id="existing-iframe-example" width="640" height="360"  
             src="https://www.youtube-nocookie.com/embed/ImodOQmAAns?rel=0&amp;loop=1&amp;autoplay=1&mute=1&enablejsapi=1" frameBorder="0">
         </iframe>*/}


				<Link to="" className="logo logo-topvideo" style={{ zIndex: 3, display: "block", margin: "0px auto" }}>
					<img src="web/img/move-it-icon.png" className="logo-icontop" data-aos="fade-down" data-aos-delay="900" data-aos-duration="1600" data-aos-easing="ease-in-out" alt="Move It Logo" />
					<img src="web/img/moveit-logosingle.png" className="moveitlog-center" data-aos="slide-right" data-aos-delay="500" data-aos-duration="1200" data-aos-easing="ease-in-out" alt="Move It Logo 2" />
					<img src="web/img/move-it--tagline.png" className="moveit-tagline" data-aos="slide-left" data-aos-delay="700" data-aos-duration="1400" data-aos-easing="ease-in-out" alt="Move It Logo 3" />
				</Link>
			</section>
			<Header></Header>
			<main>
				<div className={section == 1 || section == 2 || section == 3 ? "accordion-section has-active" : "accordion-section"} >
					<section className="hero full" style={{ backgroundImage: `url("web/img/04.jpg")` }} >
						<article className="narrow">
							<div>
								<h1 className="h2 mb-3">On Demand Moving, Delivery, & Junk Removal Service <br /> Truck & Muscle, Anytime You Need It™</h1>
								<p>

									<a href="https://apps.apple.com/us/app/move-it-moving-delivery/id1487797537"><img className="mr-2" src="web/img/app_store-btn.png" alt="Apple Store" /></a>
									<a href="https://play.google.com/store/apps/details?id=com.moveit.customer&hl=en&gl=US"><img className="" src="web/img/play_store-btn.png" alt="Play Store" /></a>
								</p>
							</div>
						</article>
					</section>
					<div className="accordions">
						{section == 1 || section == 2 || section == 3 ?
							<>
								<span className="close" onClick={() => setsection(0)} ><i className="fa fa-times" ></i></span>
							</>
							: ''}

						<section className={section == 1 ? "active" : ""} onClick={() => setsection(1)}>
							<h5>Move It Services</h5>

							<article>
								<div className="grid grid-lg">
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<img className="full" src="web/img/05.jpg" alt="Move It Services" />
									</div>
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<h3 className="flourish text"><span className="flourish-square"><svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1" ><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span><strong className="f-36">Move It Services</strong><strong><em><em><u><br></br></u></em></em></strong><strong><em><em><u><br></br></u></em></em></strong>Move It is there for any of your small move, store or home pick up and delivery, junk removal, or just extra muscle around the house needs.</h3>
										<p><Link to="/move-it-services" className="inline-button alt inverse margin-top" >Learn More <i className="fa fa-chevron-right"></i></Link></p>
									</div>
								</div>
							</article>
						</section>
						<section className={section == 2 ? "active" : ""} onClick={() => setsection(2)}>
							<h5>How Move It Works</h5>
							{/*<<span className="close" onClick={() => setsection(0)} ><i className="fa fa-times" ></i></span>*/}

							<article>
								<div className="grid grid-lg">
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<img className="full" src="web/img/06.jpg" alt="How Move It Works" />
									</div>
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<h3 className="flourish text"><span className="flourish-square"><svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span><strong className="f-36">How Move It Works</strong><strong><em><em><u><br></br></u></em></em></strong><strong><em><em><u><br></br></u></em></em></strong> Tell us when, where, and what you need delivered or moved</h3>
										<p><Link to="/how-it-works" className="inline-button margin-top" >Learn More <i className="fa fa-chevron-right"></i></Link></p>
									</div>
								</div>
							</article>
						</section>
						<section className={section == 3 ? "active" : ""} onClick={() => setsection(3)}>
							<h5>Why Choose Move It</h5>
							{/*<span className="close" onClick={() => setsection(0)} ><i className="fa fa-times"></i></span>*/}

							<article>
								<div className="grid grid-lg">
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<img className="full" src="web/img/07.jpeg" alt="Why Choose Move It" />
									</div>
									<div className="unit-1-2 unit-1-1-md unit-vertical-center margin-bottom">
										<h3 className="flourish text"><span className="flourish-square"><svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span><strong className="f-36">Why Choose Move It</strong><strong><em><u><br></br></u></em></strong><strong><em><u><br></br></u></em></strong>Award winning user friendly app with exceptional service</h3>
										<p><Link to="/why-choose-us" className="inline-button margin-top" >Learn More <i className="fa fa-chevron-right"></i></Link></p>
									</div>
								</div>
							</article>
						</section>


					</div>
				</div>
				<section className="slate padding-sm quote quote quote">
					<article>
						<div className="grid grid-lg grid-center padding-top-sm">
							<div className="unit-1-4 unit-1-2-md unit-1-1-sm margin-bottom-md">
								<div className="text-center">
									<h2 className="icon margin-bottom-sm">
										<img className="animated-icon invisiblec w-250" src="web/img/Calendar_Icon.gif" data-src="web/img/Calendar_Icon.gif" alt="Est. 2019  years 5 in business" />
									</h2>
									<h5 className="bold-highlight text-uppercase txt-20">Est.<strong> 2019 </strong> years 5 <br />in business</h5>
								</div>
							</div>
							<div className="unit-1-4 unit-1-2-md unit-1-1-sm margin-bottom-md">
								<div className="text-center">
									<h2 className="icon margin-bottom-sm">
										<img className="animated-icon invisible w-250" src="web/img/Building_Icon2.gif" data-src="web/img/Building_Icon2.gif" alt="5,700+ Move It jobs completed " />
									</h2>
									<h5 className="bold-highlight text-uppercase txt-20"><strong> 5,700+ </strong> Move It jobs completed </h5>
								</div>
							</div>
							<div className="unit-1-4 unit-1-2-md unit-1-1-sm margin-bottom-md">
								<div className="text-center">
									<h2 className="icon margin-bottom-sm">
										<img className="animated-icon invisible w-250" src="web/img/Map_Icon2.gif" data-src="web/img/Map_Icon2.gif" alt="Expected to be nationwide by end of 2024" />
									</h2>
									<h5 className="bold-highlight text-uppercase txt-20">Expected to be  <br></br><strong>nationwide by</strong> end of 2024</h5>
								</div>
							</div>
						</div>
					</article>
				</section>
				<section className="">
					<article>
						<div className="grid grid-center margin-bottom-lg">
							<div className="unit-2-3 unit-1-1-md">
								<h3 className="flourish text"><span className="flourish-square"><svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>Move It’s revolutionary new app provides the convenience and peace of mind of on demand delivery, moving, junk removal, or just extra muscle service</h3>
							</div>
						</div>
						<div className="highlights">
							<div className="highlight">
								<div className="image">
									<img src="web/img/easy-use.png" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" alt="About Us" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>About Us</h4>
										<p className="mb-0">Find out why Move It has become the most talked about on demand app for small moves and deliveries</p>
										<Link to="/about" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
							<div className="highlight">
								<div className="image">
									<img src="web/img/06.jpg" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" alt="See Our Services" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>See Our Services</h4>
										<p className="mb-0">Move It Pros & Muscles will</p>
										<p className="mb-0">Carefully load and deliver your items</p>
										<p className="mb-0">Where and when you need</p>
										<Link to="/move-it-services" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
							<div className="highlight">
								<div className="image">
									<img src="web/img/let-go.png" data-aos="fade-left" data-aos-delay="100" data-aos-duration="500" data-aos-easing="ease-in-out" alt="Move It For Business" />
								</div>
								<div className="content">
									<div className="content-container">
										<h4>Move It For Business</h4>
										<p className="mb-0">Track real-time progress</p>
										<p className="mb-0">In app call & text</p>
										<p className="mb-0">Pay, tip and review in app or online</p>
										<Link to="/moveit-business" className="plink">Learn More</Link>
									</div>
								</div>
							</div>
						</div>
					</article>
				</section>
				<section className="slate client-padd">
					<article>
						<div className="grid grid-lg grid-center padding-top-sm">
							<div className="grid-center margin-bottom-sm">
								<div className="unit-2-3 unit-1-1-md">
									<h3 className="flourish text"><span className="flourish-square z-dx1"><svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 150 150" width="150" height="150" preserveAspectRatio="xMidYMid meet" style={{ width: "100%", height: "100%", transform: "translate3d(0px, 0px, 0px)" }}><defs><clipPath id="__lottie_element_147"><rect width="150" height="150" x="0" y="0"></rect></clipPath></defs><g clipPath="url(#__lottie_element_147)"><g style={{ display: "block" }} transform="matrix(1,0,0,1,459,133.5)" opacity="1"><g opacity="1" transform="matrix(1,0,0,1,0,0)"><path strokeLinecap="butt" strokeLinejoin="miter" fillOpacity="0" strokeMiterlimit="4" stroke="rgb(255,149,162)" strokeOpacity="1" strokeWidth="10" d=" M-317,-65 C-317,-65 -317,-125 -317,-125 C-317,-125 -451,-125 -451,-125 C-451,-125 -451,8 -451,8 C-451,8 -394,8 -394,8"></path></g></g></g></svg></span>WHAT OUR CUSTOMERS ARE SAYING <br /> </h3>
								</div>
							</div>
							<div className=" row">
								<div className="container full-width">
									<Slider {...slider_settings} className="bf-testimonial-slick">
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													This app makes moving and disposing of items so easy and convenient. I don’t have a large vehicle or the strength to move boxes and furniture. Great job from team MoveIt!
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Shepard</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Exceptional customer service especially with multiple locations. Verbal communication by phone is constant. Especially if you can't be there. I was in a rehab center needing help with a move.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Sandra</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Bought some couches at Big Lots and had no idea how I was going to get home till another customer recommended Move It. Thank you for that. Love the idea. It's just like Uber or instacart type app.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Lyons</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Move It is a bit of a brilliant app. Made it very easy to get my items I purchased from the hardware store. Too big to fit in my vehicle. Truck came in an hour. Very easy. Wish I booked them before I purchased so I didnt have to wait at all. Haha.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Payne</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													I own Clever Designs an interior decorating company and I found a diamond in the rough! I've used them multiple times now for store pick up and delivery to homes. Even junk removal. The guys that come are clean, friendly, and always get the job done efficiently. Love the service. Love Move It!
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Conley</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Use Move It for any delivery or moving needs. They even do junk removal. I've used other apps before and this was great. Better than Task Rabbit and another one I can remember the name of. Maybe Thumbtack. Used them twice now for a labor only job and a store pick up. HIghly recommend.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Rosario</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													I like to move it, move it! Amazing...thank you for being there when I needed you to help with setting up party tables and chairs. If you ever need extra hands select their Labor Only option. Thanks again guys.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Sykes</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													They sent really muscular people to get the job done, very happy with the service. No complaints and the team was great.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Maynard</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													App is amazing. Very happy with quality of delivery done. They took extra care to make sure none of my stuff was damaged.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Larsen</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													I own Discount Furniture store in Las Vegas and I use Move It a few times a week to deliver to my customers. They do a nice job. Always on time, never any complaints. The price is very reasonable.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Pena</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Exceptional customer service. I am an amputee. The moves I had was 3 locations. There were 2 moves. I couldn't be there for the move. Move It followed written and verbal instructions and communication was perfect. I recommend this company for any move especially one that is particularly demanding and difficult.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Sandra</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Move It app and service is simply outstanding! Thank you for my quick Home Goods pick up and delivery.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Hayley</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Absolutely fantastic! Used Move It to move some furniture from my moms house to my house. Helpers were on time, nice as can be, and great price. Thank you very much.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Hayden</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													I needed to move all my furniture into garage while we completely re-did our floors. Move It helpers were amazing. Kind and fast. The app is so easy to use. Thank you so much. They will be coming back to put furniture back.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Jaqueline</h4></div>
											</div>
										</div>
										<div className="bf-testimonial-slick-single">
											<div className="bf-testimonial-icon">
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
												<Link to=""><i className="fas fa-star"></i></Link>
											</div>
											<div className="bf-testimonial-title">
												<p>
													Wonderful app and service! Needed to get my new outdoor furniture home and set up…Move It did it all! Can’t thank you enough. Very impressive app and nice helpful guys.
												</p>
											</div>
											<div className="bf-testimonial-author-info">
												<div className="author-name"><h4>Katie</h4></div>
											</div>
										</div>



									</Slider>
								</div>

							</div>

						</div>
					</article>
				</section>
				<section className="work white servicw-sliderbox">
					<article>
						<div className="callout">
							<div data-aos="fade-left" data-aos-delay="100">
								{/* <h2>Get the Moving and Delivery Help You Need, When You Need It</h2> */}
								<h2>Connect With An Award-Winning On-Demand Moving Company In The US</h2>

								<p>Not having a truck or needing extra muscle is always challenging.
									From removing junk to having your furniture delivered,
									you’ll have to manage multiple things simultaneously and it is stressful.
									If you need technical assistance, we recommend getting
									in touch with our team at Move It.</p>
								<p>Here’s all you need to know about our local pick-up and delivery services. </p>
								<p> At Move It, we’ve made a world-class online platform to help you get furniture delivery services. As a leading local pick-up and delivery company, we currently serve clients in Las Vegas, Denver, Tucson, Phoenix, and San Diego. Our clients use our online platform to connect with professional movers and helpers to complete small moves, store pick up, delivery, junk removal, or extra muscle needs. </p>
								<p>We’ve got a expert helpers that have trucks to help you transport your precious belongings from one place to another. We can also help your get brand-new furniture from your favorite store and help you transport it to your house safely.</p>
								<p>Our team also provides junk removal services for our esteemed customers. We strive to make your life easier by providing extensive moving solutions. We’ll pick-up the junk from your property and deliver it to the local dumping site.</p>
								<p><Link to="/contact-us">Reach out to our team</Link>&nbsp;Reach out to our team for more details about our same-day delivery services.</p>
							</div>
						</div>
						<div className="grid margin-top-lg row">

							<Slider {...slider_settings_2} className="carousel">
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/06.jpg" alt="Labor Only" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Labor Only</div>
												<div className="card-text">
													<p>No truck needed, but a ton of muscle</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-2.png" alt="Apartment Move" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Apartment Move</div>
												<div className="card-text">
													<p>Studio or 1-bedroom (max. 700 sq. ft.)</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-3.png" alt="Donate Goods" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Donate Goods</div>
												<div className="card-text">
													<p>Convenient way to help those in need</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/moveit-fb-post.jpg" alt="Facebook Marketplace, Offer Up, Craigslist" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Facebook Marketplace, Offer Up, Craigslist</div>
												<div className="card-text">
													<p>Get your great find delivered</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-6.png" alt="Garden / Nursery" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Garden / Nursery</div>
												<div className="card-text">
													<p>Star Nursery, Moon Valley Nursery for examples here locally</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-7.png" alt="Junk Removal" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Junk Removal</div>
												<div className="card-text">
													<p>Your Move It Pro will pick up your item(s) and take to dump</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-8.jpeg" alt="Store Pick Up & Delivery" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Store Pick Up & Delivery</div>
												<div className="card-text">
													<p>Get your in store purchase home</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-9.jpg" alt="Small Move" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Small Move</div>
												<div className="card-text">
													<p>An easy way to move 1 or 2 items</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div>
									<div className="servicebox">
										<div className="card-header">
											<img src="web/img/service-5.png" alt="Other" />
										</div>
										<div className="card-body">
											<div className="card-content">
												<div className="card-title">Other</div>
												<div className="card-text">
													<p>Move It can help move almost anything</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Slider>
						</div>
					</article>
				</section>
				<Link className="section red padd-50" to="/contact-us">
					<article className="text-center">
						<h6 className="h2">Still have  <strong> questions? </strong></h6>
						<span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
					</article>
				</Link>
			</main>
			<Footer></Footer>
		</>
	);
}

export default Home;
