import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Routes,Route,Link,NavLink,generatePath,useHistory } from 'react-router-dom';
import Header from './layout/Header'; //Include Heder
import Footer from './layout/Footer'; //Include Footer
import MetaHelmet from './metaHelmet'; //Include metaHelmet


function faq() {
    return (
        <>
        <MetaHelmet title="FAQ, Frequently Asked Questions, Help - Move It" description="Find answers to your questions about Move It Pro & Muscle, pricing and services." />
        <Header></Header>
           <main>
                <section className="hero clean faq-andimg" style={{backgroundImage: `url("web/img/banner-faq.jpg")`}}>
                    <article>
                        <div className="grid">
                            <div className="unit-2-3 unit-1-1-md">
                                <h1 className="h2 f-46">Frequently Asked Questions</h1>
                            </div>
                        </div>
                    </article>
                </section>
    
                <section className="slate faq-accordian">
                   <div className="container">
    				  <div className="row">
    					<div className="col-md-12 mb-5">
    					  <div className="tabs">
    						  <div className="tab">
    							<input type="radio" id="rd1" name="rd"/>
    							<label className="tab-label" htmlFor="rd1">What is Move It?</label>
    							<div className="tab-content">
    							  Move It is an on demand moving and delivery service here to help anytime you need. From a small house move, to a furniture store pickup and delivery, extra muscle around the house, to just getting junk hauled away, Move It has trucks and muscle ready to help you. <br/> <br/>
    							  
    							  We connect you with highly vetted truck owners and labor to carefully load and deliver your items when and where you need. <br/>
    							  Real time tracking, in-app call and text. Pay, tip and review in-app or online. Quick free pricing estimates. Get a quote today!
    							  <br/> <br/>
    							  Think of us as the Uber or Lyft of moving, delivery, and junk removal. We connect you with local truck owners (called "Move It Pros") and local labor (called “Move it Muscle”) 
    							  <br/> <br/>
    							  On Demand Delivery and Move Services
    							   <br/> <br/>
    							   Truck & Muscle, Anytime You Need It ™ 

    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd2" name="rd"/>
    							<label className="tab-label" htmlFor="rd2">Who are the Move It Pros and Muscles?</label>
    							<div className="tab-content">
    							  Every Move It Pro and Muscle is an independent contractor that is thoroughly background checked and vetted by Move It and continuously rated and reviewed by other Move It customers. Rest assured that Move It aims to work with only the best contractors who can provide an incredibly high level of service and pays close attention to their ratings to ensure that's the case.
    							</div>
    						  </div>
    						  
    						  <div className="tab">
    							<input type="radio" id="rd3" name="rd"/>
    							<label className="tab-label" htmlFor="rd3">How much does a Move It cost?</label>
    							<div className="tab-content">
    							  Pricing is personalized based on the details of every Move It. In general, the cost varies based on the number and type of items being moved, the number of Pros and Muscles requested, the distance between pickup and drop off and the service level selected. To get a quote and book, visit gomoveit.com or download our free app.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd4" name="rd"/>
    							<label className="tab-label" htmlFor="rd4">What's the difference between curbside, front door and standard service?</label>
    							<div className="tab-content">
    							  With standard service, your Pro(s) will pick-up and place your items from and to a room of your choosing. With Curbside, your Pro(s) will pick-up the items at the curb and drop them off at the curb. You are responsible for getting it to and from the Pro's vehicle. Or your items can be dropped off at the curb. Front door service gets your items as close to your front door as possible. Standard Service places your items in the room(s) of your choosing. Curbside is the cheapest option, followed by front door service, and then Standard Service
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd5" name="rd"/>
    							<label className="tab-label" htmlFor="rd5">Do you have contactless service?</label>
    							<div className="tab-content">
    							  Yes! Just select curbside or front door service upon checking out. You do not need to have any contact with your helper(s). You will be notified upon completion of every move and delivery.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd6" name="rd"/>
    							<label className="tab-label" htmlFor="rd6">How do I pay my Move It Pros and Muscles?</label>
    							<div className="tab-content">
    							  All payments are done directly through Move It app or website via credit card or many other electronic forms of payment offered. No cash will be accepted. You may tip your helper in cash. 
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd7" name="rd"/>
    							<label className="tab-label" htmlFor="rd7">Can I tip my Pros(s) and Muscle(s)?</label>
    							<div className="tab-content">
    							  Yes, and encouraged for great jobs, but tipping is completely optional (though much appreciated by your helpers). You'll be able to tip your Pro(s) and Muscle(s) directly through the Move It app once the Move It has been completed. Helpers receive 100% of the tip and the tip amount is added to the cost of your Move It invoice.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd8" name="rd"/>
    							<label className="tab-label" htmlFor="rd8">Is there a cancellation fee?</label>
    							<div className="tab-content">
    							  In the event that you cancel your Move It with more than 24 hours notice, your hold is released. If you cancel with less than 24 hours notice and a Pro or Muscle is scheduled, a 20% cancellation fee is applied. In the event that your Move It is “on the way” or "started" and/or your Pro(s) have arrived at the first location and cannot complete your Move It due to cancellation, rescheduling, or any reasons outside of their control, a 50% cancellation fee is applied.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd9" name="rd"/>
    							<label className="tab-label" htmlFor="rd9">How do I edit my Move It?</label>
    							<div className="tab-content">
    							  You can edit the details of your Move It to make changes or include additional information. To edit your Move It, open the Move It app or log into gomoveit.com. Look on the home screen of your Move It app. Press “Pending” or “Upcoming” on top of the screen to find your Move It. Press the Move It job you'd like to edit, then select the "Edit" button. You'll be able to change the locations, items moved, date, time, and more. When you're finished editing, continue by clicking the pink "Save and Continue" button on the bottom of the screen and finally click the "Update Move It" button on the last screen to ensure your changes are saved. 
    							     <br/><br/>
    							    <b>Note:</b> your job may move to “pending” again to wait for helpers to accept the job, again. 
    							     
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd10" name="rd"/>
    							<label className="tab-label" htmlFor="rd10">How do flexible time windows work?</label>
    							<div className="tab-content">
    							  If you select a flexible time window (a time period greater than 30 minutes) for your Move It, we'll look for the Pro(s) and/or Muscle(s) available anytime in that period. The Pros / Muscles that select the Move It job will select it at a specific time 30 minute time frame within your time window, so your Move It job may not necessarily start at the first time period you selected for your flexible time window. Your helper will notify when they are on their way inside your time frame and what your helper selected as their 30 minute window to show up. 
    							</div>
    						  </div>
    						  
    						  
    						  <div className="tab">
    							<input type="radio" id="rd11" name="rd"/>
    							<label className="tab-label" htmlFor="rd11">How do I reschedule my Move It?</label>
    							<div className="tab-content">
    							  To reschedule your Move It, open the Move It app or log in to gomoveit.com. Look in your Move It app and click on “Upcoming”  for the Move It you'd like to reschedule, then select the "Edit" button beside its details. Proceed through the flow again until you reach the time and date screen. Change the date or time of your Move It to the desired new start time. Then, proceed through the flow, making sure to click the final "Update Move It" button on the last screen. If your Move It has already been scheduled with a Pro or Muscle, we'll see if they're available for the new time you've requested, and if not, we'll get started on finding you a new Pro and/or Muscle right away.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd12" name="rd"/>
    							<label className="tab-label" htmlFor="rd12">How do I communicate with my Pro(s) and Muscle(s)?</label>
    							<div className="tab-content">
    							  You and your helpers will be able to text message and call one another via the Move It app or website. Communication through the app is encouraged. 
    							</div>
    						  </div>
    						  
    						  <div className="tab">
    							<input type="radio" id="rd13" name="rd"/>
    							<label className="tab-label" htmlFor="rd13">How do I know how big my items are?</label>
    							<div className="tab-content">
    							  We have options for you to compare you item to common household items. Like a love seat couch, 6 person dining table, 65” TV, etc. Tape measures will be a huge help. Just explain in item details what it is and compare it to other items if you need to. We also, require pictures. Adding ALL the information possible for the items you need moved is very important. It allows the helpers to know if they can move the item or not. You will give the information in inches and weight. We don’t want a helper showing up and not being to do the job. Remember 12 inches = 1 foot. 
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd14" name="rd"/>
    							<label className="tab-label" htmlFor="rd14">Will Pro(s) or Muscle(s) pack boxes?</label>
    							<div className="tab-content">
    							  Helpers do not pack boxes. It is expected that you will have everything packed and ready for transport before your help arrives. <br/>
    							  If you wanted Labor Only help, you can put this in description to see if a helper is willing to this job. But it is not required for helper
.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd15" name="rd"/>
    							<label className="tab-label" htmlFor="rd15">Will Pro and Muscle disassemble or assemble furniture?</label>
    							<div className="tab-content">
    							 Pros and Muscles can break down items that need to fit through doorways or tight hallways in order to move, like unscrewing legs on a couch. Or disconnecting a bed frame. However, this service does not extend to full disassembly or assembly on furniture that takes longer to break down, like disassembling or assembling a desk or bookcase. You can always ask. Most helpers will want to do anything to make sure you are satisfied. 
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd16" name="rd"/>
    							<label className="tab-label" htmlFor="rd16">Do Move It Pros and Muscles wrap items?</label>
    							<div className="tab-content">
    							  Helpers will come equipped with straps and tarps, but if there is any item you are concerned about protecting, it's best to wrap it up yourself beforehand.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd17" name="rd"/>
    							<label className="tab-label" htmlFor="rd17">What happens if my Move It Muscle time block is shorter than expected?</label>
    							<div className="tab-content">
    							  To fairly compensate Move It Muscle(s) for committing to the time block requested, once your Move It has started, you will not be able to reduce the duration of a Muscle move (Labor Only) for the first hour. The first hour is charged no matter what, then it is prorated after the first hour.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd18" name="rd"/>
    							<label className="tab-label" htmlFor="rd18">How should I list a bed?</label>
    							<div className="tab-content">
    							  We get this one a lot! Each size bed is listed in the app. <br/><br/>
    							  If you enter "(Size of Your Bed) Bed" in search bar it includes a mattress, box spring, and bed frame. Feel free to add each of the parts of your bed individually as well, it's up to you!

    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd19" name="rd"/>
    							<label className="tab-label" htmlFor="rd19">What if an item doesn't fit into my home?</label>
    							<div className="tab-content">
    							  In the event your item can't be delivered because it doesn't fit through the door, your Move It Pro can leave the item outside your home or return it to the pickup location for an additional fee. It is highly encouraged to take the appropriate measurements of all doorways, hallways and other spaces in the delivery path to ensure the items will fit before booking a Move It.
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd20" name="rd"/>
    							<label className="tab-label" htmlFor="rd20">What CANNOT be moved?</label>
    							<div className="tab-content">
    							  There are a few things Move It Pros and Muscles cannot move. Pretty much anything that requires more than two people or traditionally specific items that require professional movers or waste disposal companies. 
    							  
    							 <br/><br/>
    							 - No above the ground Spas <br/>
    							 - No Pianos <br/>
    							 - No Pool / Billiard tables (small kids size may be acceptable) <br/>
    							 - No toxic chemicals or hazardous materials <br/>
    							 - No Animals of any kind (aquariums must be empty) <br/>
    							  
    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd21" name="rd"/>
    							<label className="tab-label" htmlFor="rd21">What happens if my items are damaged during the Move It?</label>
    							<div className="tab-content">
    							   Move It offers Damage Protection to protect your stuff in the unlikely event something is damaged during your Move It, including water damage, scratches, marks, tears, breakage, etc.<br/><br/>
    							  
    							  Move Its automatically come with $250 of damage protection (see below for exceptions). If you'd like to add additional protection, you can do so before you complete booking your Move It.<br/><br/>
    							  
    							  You can increase the amount of protection with additional $250 in coverage for only $5.<br/><br/>
    							  
    							  In the event that damage occurs to your item(s), please fill out this form to start a claim.<br/><br/>
    							  
    							  <b>Please Note:</b> Damage protection only applies to items being transported and cannot be modified once your Move It has started. <br/><br/>
    							  You must report any damage within 3 days of the Move It. During the damage claim process you must respond to action items within 30 days, failure to do so will result in nullification of the claim.
    							</div>
    						  </div>
    						  
    						  <div className="tab">
    							<input type="radio" id="rd23" name="rd"/>
    							<label className="tab-label" htmlFor="rd23">What if my walls, floors or other elements of my home are damaged during the Move It?</label>
    							<div className="tab-content">
    							In the unlikely event that there is damage to your home (walls, floors, ceiling, etc.) your Move It is protected via our commercial liability insurance. We do not require an additional purchase for this protection. <br/><br/> In the event that damage occurs, please <Link to="/contact-us">contact us</Link> to start a claim.

    							</div>
    						  </div>
    						  <div className="tab">
    							<input type="radio" id="rd24" name="rd"/>
    							<label className="tab-label" htmlFor="rd24">How do I become a Move It Pro or Muscle?</label>
    							<div className="tab-content">
    							  You can  apply to <Link to="/helper-login"> become a Move It Pro and/or Muscle </Link> here.
    							</div>
    						  </div>
							  <div className="tab">
    							<input type="radio" id="rd25" name="rd"/>
    							<label className="tab-label" htmlFor="rd25">Who do I contact if I have more questions?</label>
    							<div className="tab-content">
								Please contact <a href="mailto:helpers@gomoveit.com"  target="_blank">helpers@gomoveit.com</a> whenever you have questions. You can also use the Helper Support chat on the app.
    							</div>
    						  </div>
    						 
    						  
    						</div>
    
    
    					</div>
    				  </div>
    			   </div>
    			</section>
               
                <Link className="section red padd-50" to="/contact-us">
                    <article className="text-center">
                        <h6 className="h2">Still have  <strong> questions? </strong></h6>
                        <span className="button outline inverse">Contact Us <i className="fa fa-chevron-right"></i></span>
                    </article>
                </Link>
            </main>
        <Footer></Footer>
        </> 
    ); 
}

export default faq;
